export const initTrackSpecialLinks = () => {
  for (const link of document.querySelectorAll('a[href^="tel:"]')) {
    link.addEventListener("click", () => {
      // Send a custom event for telephone clicks to plausible, including the link href as additional data
      if (window.plausible) {
        window.plausible("Outbound Link: Click", { props: { url: link.href } })
      }
    })
  }
  // Track email links
  for (const link of document.querySelectorAll('a[href^="mailto:"]')) {
    link.addEventListener("click", () => {
      // Send a custom event for email clicks to plausible, including the link href as additional data
      if (window.plausible) {
        window.plausible("Outbound Link: Click", { props: { url: link.href } })
      }
    })
  }
}
